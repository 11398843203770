<template>
<div>

    <v-row no-gutters>
        <v-col>
            <v-card outlined error>

                <v-data-table height="210" :headers="headers" dense :items="getGuests" sort-by="calories" class="elevation-0" :footer-props="{
        itemsPerPageText: 'Itens por pagina',
        itemsPerPageOptions: [5],
        showFirstLastPage: true,
      }">
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title>Hospedes</v-toolbar-title>
                            <v-divider class=" mx-4" inset vertical>
                            </v-divider>
                            <AutocompleteSearchGuest :errors="errors" v-model="guest" @key-word="keyWord" :dense="true" class="mt-6" />
                            <v-btn color="primary" class="mb-1 ml-4" @click="sendAddGuest()">
                                <v-icon >
                                    {{ icons.mdiPlus }}
                                </v-icon>
                                adicionar
                            </v-btn>
                            <v-btn color="secondary" dark class="mb-1 ml-4" @click="showRegiterSimple()">
                                <v-icon >
                                    {{ icons.mdiPlus }}
                                </v-icon>
                                Hóspede
                            </v-btn> 
                        </v-toolbar>
                    </template>
                    <template v-slot:item.actions="{ item }">

                        <v-icon v-if="item.origin != 'unknown'"  color="error" @click="removeGuest(item)">
                            {{ icons.mdiDeleteOutline }}
                        </v-icon>
                        <v-icon v-else  color="error" @click="removeUnknown(item)">
                            {{ icons.mdiDeleteOutline }}
                        </v-icon>


                    </template>
                    <template v-slot:no-data>
                        <div> Adicione o primeiro hóspede</div>
                    </template>

                </v-data-table>

            </v-card>
        </v-col>
    </v-row>
  <RegisterSimpleGuest v-model="dialogRegisterSimpleGuest"></RegisterSimpleGuest>
</div>
</template>

<script>

import AutocompleteSearchGuest from '@/components/AutocompleteSearchGuest.vue'
import {
    mdiDeleteOutline,
    mdiPlus
} from '@mdi/js'
import {
    mapGetters,
    mapMutations
} from 'vuex'
import RegisterSimpleGuest from './RegisterSimpleGuest.vue'

export default {
    components: {
        AutocompleteSearchGuest,
        RegisterSimpleGuest
    },
    props:{
      errors:{}
    },


    data: () => ({
        dialogRegisterSimpleGuest:false,
        guests: [],
        errors: {},
        guest: '',
        keyWordGuest:null,
        headers: [{
                text: 'NOME',
                align: 'start',
                value: 'name',
            },
            // {
            //     text: 'TELEFONE',
            //     value: 'cell_phone',
            // },
            // {
            //     text: 'EMAIL',
            //     value: 'email',
            // },
            {
                text: '',
                align: 'end',
                value: 'actions',
            }
        ],
        icons: {
            mdiDeleteOutline,
            mdiPlus
        }

    }),
    computed: {
        ...mapGetters('reserve', ['getGuests'])
    },
    methods: {
        ...mapMutations('reserve', ['addGuest','removeGuest','addGuestUnknown','removeUnknown']),
        keyWord(val){
          this.keyWordGuest = val[0].toUpperCase() + val.substring(1)
        },
        sendAddGuest() {

            if(this.guest){
              this.addGuest(this.guest)
              this.guest = ''
              this.guests = []
            }else{
              const guestUnknown = {
                name:this.keyWordGuest,
                origin:'unknown',
              }
              this.addGuestUnknown(guestUnknown)
            }
            this.$emit('change')
        },
        showRegiterSimple(){
          this.dialogRegisterSimpleGuest = true
        },
        // sendRemoveUnknown(item){
        //   console.log(item)
        // }

    }
}
</script>

<style scoped>
nodata {
    height: 170px;
}
</style>
