<template>
  <v-select chips multiple outlined v-model="room" return-object :loading="loading" :disabled="loading" label="Quartos" :items="rooms" item-text="description" item-value="id" ></v-select>
</template>

<script>
import { mapGetters } from 'vuex'
export default {

    components: {},
    props: {
        value: {},
    },

    data: () => ({
        loading: false,
        room: '',
        rooms:[],

    }),

    watch: {
        value(val) {
            this.room = val
            
        },
        room(){
          this.$emit('input',this.room)
        }

    },

    created() {
      this.rooms = this.getRooms
      this.room = this.value

    },
    computed: {
      ...mapGetters('select',['getRooms'])
    },

    methods: {



    },
}
</script>
